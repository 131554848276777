import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [ "button", "input" ]

  static values = {
    default: String,
    locale: String
  }
  connect() {
    this.inputTarget.addEventListener('change', this._onChange.bind(this));
    this.inputTarget.addEventListener('promote:datepicker-change', this._onDatePickerChange.bind(this));
  }

  displayDatePicker() {
    this.inputTarget.showPicker();
  }

  _onChange(event) {
    this._updateDate(event.target.value);
  }

  // This method is used when calling with a custom change event
  // The custom event must be separated from the change event on the component to avoid recursion
  // Example of custom even dispatch:
  // <inputTarget>.dispatchEvent(new CustomEvent('promote:datepicker-change', {detail: { value: <value> } }));
  _onDatePickerChange(event) {
    const value = event.detail.value;

    if (value === '') {
      this._clearDate();
      return;
    }

    this._updateDate(value);
  }

  _clearDate() {
    this.buttonTarget.querySelector('.admin-button-text').innerHTML = this.defaultValue;
    this.inputTarget.value = '';
  }

  _updateDate(date) {
    this.buttonTarget.querySelector('.admin-button-text').innerHTML = this._formattedDate(date);
    this.inputTarget.value = date;
  }

  _formattedDate(value) {
    if (value === '') {
      return this.defaultValue;
    }

    let date = new Date(value);
    let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    let locale = this.localeValue;

    try {
      return `${utcDate.getDate()} ${utcDate.toLocaleString(locale, { month: 'short', year: 'numeric' })}`;
    } catch (err) {
      return `${utcDate.getDate()} ${utcDate.toLocaleString('en', { month: 'short', year: 'numeric' })}`;
    }
  }
}
