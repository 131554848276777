import { ajax } from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'reload'];

  connect() {
    document.addEventListener('promote:content-loader--reload', this._reloadContent.bind(this));
  };

  disconnect() {
    document.removeEventListener('promote:content-loader--reload', this._reloadContent.bind(this));
  };

  loadContent(event) {
    if (this._contentPreviouslyLoaded(this.contentTarget) || this._disabled(this.contentTarget)) {
      return;
    }

    this._loadData(this.contentTarget);
  };

  _reloadContent(event) {
    this.reloadTargets.forEach(target => {
      if (this._contentPreviouslyLoaded(target)) {
        this._loadData(target);
      }
    });
  };

  _loadData(element) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(element),
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  };

  _url(element) {
    return element.dataset.contentLoaderUrlValue;
  };

  _contentPreviouslyLoaded(element) {
    return element.dataset.contentLoaderLoaded === 'true';
  };

  _disabled(element) {
    return element.classList.contains('disabled')
  };
}
