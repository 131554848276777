import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";
import PromoteHelpers from "../../ng_admin/deprecated_promote_helpers";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ["menu", "menuItem", "icon", "label", "promptOption", "component",
    'hiddenField', "menuFilter", "clearFilterIcon"]

  static values = {
    direction: String,
    prompt: String,
    placement: String,
    multiselect: Boolean,
    selectedCopy: String
  }

  connect() {
    this.clickHandler = this._handleOutsideClick.bind(this);
    this.filterHandler = debounce(this.filterOnInput, 300).bind(this);

    this._addObserver();

    if (this.hasMenuFilterTarget) {
      this.menuFilterTarget.addEventListener('input', this.filterHandler);
    }

    const preSelectedItem = this._preSelectedItem();

    if (preSelectedItem) {
      this._showPromptOption();
      this._replaceSelectedOption(preSelectedItem);
    }
  }

  disconnect() {
    document.removeEventListener('click', this.clickHandler);

    if (this.hasMenuFilterTarget) {
      this.menuFilterTarget.removeEventListener('input', this.filterHandler);
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  toggleMenu() {
    const openMenu = document.querySelector('[data-components--admin-inputs-dropdown-target="menu"].open');

    if (openMenu && openMenu.id !== this.menuTarget.id) {
      openMenu.classList.toggle('invisible');
      openMenu.classList.toggle('open');

      const dropdown = openMenu.closest('[data-components--admin-inputs-dropdown-target="component"]');
      dropdown.querySelector('[data-components--admin-inputs-dropdown-target="icon"]').classList.toggle('rotate-180');
      dropdown.classList.toggle('hover:border-purple-300');
    }

    this.menuTarget.classList.toggle('open');

    const dropdown = event.target.closest('[data-components--admin-inputs-dropdown-target="component"]');
    const selectHeight = dropdown.offsetHeight;

    if (this.directionValue === 'up') {
      this.menuTarget.style.bottom = `${ selectHeight + 2.5 }px`;

    } else {
      this.menuTarget.style.top = `${ selectHeight + 1.5 }px`;
    }

    this.menuTarget.classList.toggle('invisible');
    this.iconTarget.classList.toggle('rotate-180');
    this.componentTarget.classList.toggle('hover:border-purple-300');

    // This will reduce the number of events fired on the page by only listening to clicks when a
    // menu is open.
    if (this.menuTarget.classList.contains('open')) {
      document.addEventListener('click', this.clickHandler);
    } else {
      document.removeEventListener('click', this.clickHandler);
    }

    if (this.observer) {
      this.observer.observe(this.menuTarget);
    }
  }

  toggleOption(event) {
    let target = event.currentTarget;

    if (this.hasMenuFilterTarget) {
      this.filterReset();
    }

    if (this.multiselectValue) {
      this._toggleItem(target);
      this._replaceSelectedOption(target);
      return;
    }

    this._deselectItems();
    this._selectItem(target);
    this._setHiddenFieldValue(target);
    this._showPromptOption();
    this.toggleMenu();
    this._triggerChangeEvent();
    this._replaceSelectedOption(target);
  }

  resetDropdown(event) {
    this._replaceSelectedOption(event.currentTarget, false);
    this._hidePromptOption();
    this._resetHiddenFieldValue();
    this._deselectItems();
    this.toggleMenu();
    this._triggerChangeEvent();
  }

  loadData(event) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: event.params.url,
      type: event.params.method || "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  hideMenu(event) {
    if (this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains('hidden')) {
      this.toggleMenu();
    }
  }

  filterOnInput(event) {
    const filterValue = event.target.value.toLowerCase();

    this._toggleClearFilterIcon();

    if (this.observer) {
      this.menuTarget.style.height = 'fit-content';

      this.observer.observe(this.menuTarget);
    }

    this.menuItemTargets.forEach((item) => {

      const itemText = item.textContent.toLowerCase();

      if (itemText.includes(filterValue)) {
        this._hidePromptOption();
        item.classList.remove('hidden');
      } else {
        this._hidePromptOption();
        item.classList.add('hidden');
      }
    });
  }

  filterReset() {
    if (this._hasSelectedItems()) {
      this._showPromptOption();
    }

    if (this.observer) {
      this.menuTarget.style.height = 'fit-content';

      this.observer.observe(this.menuTarget);
    }

    this.menuFilterTarget.value = '';
    this._showItems(this.menuItemTargets);
    this._toggleClearFilterIcon();
  }

  _addObserver() {
    const rootElement = this.menuTarget.closest('.js-dropdown-intersection-root');

    if (!rootElement) {
      return;
    }

    const callback = this._adjustHeightWhenIntersecting.bind(this);
    const rootMargin = Number(window.getComputedStyle(rootElement, null).paddingBottom.split('px')[0]) + 1.5;
    const options = {
      root: rootElement,
      rootMargin: `-${rootMargin}px`,
    }

    this.observer =  new IntersectionObserver(callback, options);
  }

  _adjustHeightWhenIntersecting(entries) {
    const entry = entries[0];

    if (!entry.isIntersecting) {
      return;
    }

    if (entry.intersectionRatio < 1) {
      this.menuTarget.style.height = `${ entry.intersectionRect.height }px`;
    } else {
      this.menuTarget.style.height = 'fit-content';
    }

    this.observer.unobserve(this.menuTarget);
  }

  _handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this._closeDropdown();
    }
  }

  _closeDropdown() {
    if (this.menuTarget.classList.contains('open')) {
      this.menuTarget.classList.remove('open');
      this.menuTarget.classList.add('invisible');
      this.iconTarget.classList.remove('rotate-180');
    }

    if (this.observer) {
      this.observer.observe(this.menuTarget);
    }

    document.removeEventListener('click', this.clickHandler);
  }

  _toggleClearFilterIcon() {
    if (this.menuFilterTarget.value === '') {
      this.clearFilterIconTarget.classList.add('hidden');
    } else if (this.menuFilterTarget.value !== '') {
      this.clearFilterIconTarget.classList.remove('hidden');
    }
  }

  _showItems(items) {
    items.forEach((item) => {
      item.classList.remove('hidden');
    });
  }

  _hidePromptOption() {
    if (this.hasPromptOptionTarget) {
      this.promptOptionTarget.classList.add("hidden");
    }
  }

  _showPromptOption() {
    if (this.hasPromptOptionTarget) {
      this.promptOptionTarget.classList.remove("hidden");
    }
  }

  _hasSelectedItems() {
    return this.menuItemTargets.some((item) => item.classList.contains("js-selected"));
  }

  _deselectItems() {
    this.menuItemTargets.forEach((item) => {
      item.querySelector(".js-menu-option-check").classList.add("invisible");
      item.classList.remove("js-selected");
    });
  }

  _toggleItem(target) {
    target.querySelector(".js-menu-option-check").classList.toggle("invisible");
    target.classList.toggle("js-selected");
  }

  _preSelectedItem() {
    let selectedItem;

    this.menuItemTargets.forEach((item) => {
      if (item.classList.contains("js-selected")) {
        selectedItem = item;
      }
    });

    return selectedItem;
  }

  _selectItem(target) {
    target.querySelector(".js-menu-option-check").classList.remove("invisible");
    target.classList.add("js-selected");
  }

  _setHiddenFieldValue(target) {
    this.hiddenFieldTarget.value = target.dataset.value;
  }

  _resetHiddenFieldValue() {
    this.hiddenFieldTarget.value = '';
  }

  _replaceSelectedOption(target, display_prompt = true) {
    if (this.multiselectValue) {
      this._replaceMultiSelectOption(target);
    } else if (display_prompt && this.promptValue) {
      const targetText = target.querySelector(".js-menu-option-text");
      targetText.classList.add("truncate")

      this.labelTarget.innerHTML = `${this.promptValue} ${targetText.innerHTML}`;
    } else {
      this.labelTarget.innerHTML = target.innerHTML;

      const labelMenuOptionText = this.labelTarget.querySelector(".js-menu-option-text");

      if (labelMenuOptionText) {
        labelMenuOptionText.classList.add("truncate");
      }
    }

    const menuOptionCheck = this.labelTarget.querySelector(".js-menu-option-check");

    if (display_prompt && menuOptionCheck) {
      menuOptionCheck.classList.add("invisible");
    }

    if (this.labelTarget.querySelector(".js-menu-option-text")) {
      this.labelTarget.querySelector(".js-menu-option-text").classList.remove("ml-5");
    }
  }

  _replaceMultiSelectOption(target) {
    let selectedItems = this._numberOfSelectedItems();

    if (selectedItems === 0) {
      if (this.hasPromptOptionTarget) {
        this.labelTarget.innerHTML = this.promptOptionTarget.innerHTML;
      } else {
        this.labelTarget.innerHTML = '';
      }
    } else if (selectedItems === 1) {
      this.labelTarget.innerHTML = target.innerHTML;
    } else {
      this.labelTarget.innerHTML = `${selectedItems} ${this.selectedCopyValue}`;
    }
  }

  _numberOfSelectedItems() {
    let counter = 0;

    this.menuItemTargets.forEach((item) => {
      if (item.classList.contains("js-selected")) {
        counter++;
      }
    });

    return counter;
  }

  _triggerChangeEvent() {
    PromoteHelpers.dispatchEvent({
      target: this.hiddenFieldTarget,
      type: 'change'
    });
  }
}
