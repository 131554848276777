import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'newResourceForm',
    'resourceAssignmentsSection',
    'newResourceSection',
    'assignedResourcesHeading',
    'assignedResourcesList',
    'linkField',
    'fileField',
    'scormCloudCourseField',
    'linkInput',
    'fileInput'
  ];

  static values = {
    assignedToType: String,
    assignedToId: Number
  };

  connect() {
    // FIXME dropdown component doesn't accept data attributes so we can't add targets
    this.scormCloudCourseInput = this.newResourceFormTarget
      .querySelector('input[name="resource[scorm_cloud_course_id]"]');

    document.addEventListener(
      'promote:editor--resources:created', this.handleResourceCreated.bind(this)
    );
  }

  handleResourceCreated(event) {
    if (this.assignedToTypeValue != event.detail.assignedToType ||
        this.assignedToIdValue != event.detail.assignedToId) {
      return;
    }

    this.showResourceAssignmentsSection();
    this.newResourceFormTarget.reset();
    this.assignedResourcesListTarget.insertAdjacentHTML('beforeend', event.detail.element);
    this.assignedResourcesHeadingTarget.classList.remove('hidden');
  }

  cancelNewResource() {
    this.showResourceAssignmentsSection();
    this.newResourceFormTarget.reset();
  }

  showResourceAssignmentsSection() {
    this.resourceAssignmentsSectionTarget.classList.remove('hidden');
    this.newResourceSectionTarget.classList.add('hidden');
  }

  showNewResourceSection() {
    this.newResourceSectionTarget.classList.remove('hidden');
    this.resourceAssignmentsSectionTarget.classList.add('hidden');
  }

  showLinkField() {
    this.linkFieldTarget.classList.remove('hidden');
    this.fileFieldTarget.classList.add('hidden');
    this.scormCloudCourseFieldTarget.classList.add('hidden');
    this.linkInputTarget.disabled = false;
    this.fileInputTarget.disabled = true;
    this.scormCloudCourseInput.disabled = true;
  }

  showFileField() {
    this.fileFieldTarget.classList.remove('hidden');
    this.linkFieldTarget.classList.add('hidden');
    this.scormCloudCourseFieldTarget.classList.add('hidden');
    this.fileInputTarget.disabled = false;
    this.linkInputTarget.disabled = true;
    this.scormCloudCourseInput.disabled = true;
  }

  showScormCloudCourseField() {
    this.scormCloudCourseFieldTarget.classList.remove('hidden');
    this.linkFieldTarget.classList.add('hidden');
    this.fileFieldTarget.classList.add('hidden');
    this.scormCloudCourseInput.disabled = false;
    this.linkInputTarget.disabled = true;
    this.fileInputTarget.disabled = true;
  }
}
